import React from 'react'
import moment from 'moment'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import DatePicker from 'react-datepicker'

const InputDate = ({
  label,
  id,
  required,
  name,
  value,
  onChange,
  onBlur,
  classNames,
  error,
  validations,
  ...props
}) => (
  <div className={classNames.field}>
    {label && (
      <label className={classNames.label}>
        {label}
        {required && <span className={classNames.required}>*</span>}
      </label>
    )}

    <DatePicker
      className={classNames.input}
      name={name}
      id={id}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      dateFormat='dd/MM/yyyy'
      selected={value ? moment(value).toDate() : null}
      onChange={(value) => onChange && onChange(value)}
      onBlur={(e) => onBlur && onBlur(e.target.value ? moment(e.target.value, 'DD/MM/YYYY') : '')}
      required={required}
      {...props}
    />

    {error && (
      <div className={classNames.errors}>
        {validations.map((error, i) => (
          <div className={classNames.error} key={i}>
            {error}
          </div>
        ))}
      </div>
    )}
  </div>
)

export default withStyles(styles)(InputDate)
