import React, { useState, useEffect, useCallback } from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Flash = ({ message, clearFlash, classNames }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (message) {
      setActive(true)
      setTimeout(handleHideFlash, 8000)
    }
  }, [message])

  const handleHideFlash = useCallback(() => {
    setActive(false)
    if (clearFlash) {
      setTimeout(clearFlash, 500)
    }
  }, [clearFlash])

  return (
    <div className={classNames.wrapper}>
      <div
        className={`${classNames.root} ${active ? classNames.active : classNames.hidden}`}
        onClick={handleHideFlash}
        role='alert'
        aria-live='polite'
      >
        {message}
      </div>
    </div>
  )
}

export default withStyles(styles)(Flash)
